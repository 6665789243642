import React from "react"

const Navbar = () => (
  <nav className="navbar navbar-inverse navbar-fixed-top" role="navigation">
    <div className="container">
      <div className="navbar-header">
        <a className="navbar-brand" href="/">
          <span className="logoh"></span>
        </a>{" "}
      </div>
    </div>
  </nav>
)

export default Navbar
