import React from "react"
import { Link, graphql } from "gatsby"
// import "bootstrap/dist/css/bootstrap.css"

import Navbar from "../components/navBar"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/index.css"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges
  const authors = data.site.siteMetadata.authors

  const getAuthorName = authorId => authors.find(a => a.id === authorId).name

  const renderPosts = () => {
    return (
      <div className="home-page-container">
        {posts.map(({ node }) => (
          <div key={node.frontmatter.slug} className="post-module">
            <div className="thumbnail-hub">
              <img alt="" src={node.frontmatter.img?.publicURL} />
            </div>
            <div className="post-content">
              <h1 className="title">
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {node.frontmatter.title || node.fields.slug}
                </Link>
              </h1>
              <p
                className="description"
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
              <div className="post-meta">
                {" "}
                <span className="posted-on">
                  Posted on <time>{node.frontmatter.date}</time>
                </span>
                <span className="byline">
                  {" "}
                  by{" "}
                  <span className="author vcard">
                    {getAuthorName(node.frontmatter.author)}
                  </span>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <Layout location={location} title={siteTitle}>
        <SEO title="All posts" />
        {renderPosts()}
      </Layout>
    </>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        authors {
          id
          name
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            author
            img {
              publicURL
            }
          }
        }
      }
    }
  }
`
